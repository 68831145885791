import { Swiper, Navigation, Pagination, Autoplay } from 'swiper';
Swiper.use([Navigation, Pagination, Autoplay]);


export class Carousel {

    constructor() {

        //document.ready
        $(() => {

            const swiper = new Swiper('.swiper', {
                // Optional parameters
                loop: true,
                spaceBetween: 10,

                // If we need pagination
                pagination: {
                    el: '.swiper-pagination',
                },

                // Navigation arrows
                //navigation: {
                //    nextEl: '.swiper-button-next',
                //    prevEl: '.swiper-button-prev',
                //},

                autoplay: {
                    delay: 4000,
                },
            });

        });

    }
}