
//only import what we use.
import { library, dom, IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
    faAngleUp, faAngleDown, faBars, faUser, faCaretRight, faExternalLinkAlt, faUserPlus, faBan, faCheck, faUserEdit,
    faEye, faEyeSlash, faKey, faUsers, faFolder, faFileExcel, faFileWord, faFilePowerpoint, faFilePdf, faFile, faFileAlt, faFileAudio, faFileVideo,
    faTimes, faSquare, faCog, faFileDownload, faQuestionCircle, faBook, faBullhorn, faChevronCircleUp, faInfo, faLock, faHome, faArrowDown,
    faChartLine, faMicroscope, faVialVirus, faGlobe, faEnvelope, faMapLocationDot, faBookmark, faBullseye, faScrewdriverWrench, faQuestion,
    faClipboardList, faMagnifyingGlassChart, faMagnifyingGlass, faFileCode, faCalendarDays,
    faLocationDot, faFileSignature, faPencil, faHandHoldingDollar, faGift, faComments, faFileCircleQuestion,
    faDesktop, faShapes, faVideo,
} from "@fortawesome/free-solid-svg-icons";

library.add(faAngleUp as IconDefinition); library.add(faAngleDown); library.add(faBars); library.add(faUser); library.add(faCaretRight); library.add(faExternalLinkAlt);
library.add(faUserPlus); library.add(faBan); library.add(faCheck); library.add(faUserEdit); library.add(faEye); library.add(faEyeSlash);
library.add(faKey); library.add(faUsers); library.add(faFolder); library.add(faFileExcel); library.add(faFileWord); library.add(faFilePowerpoint); library.add(faFilePdf); library.add(faFile);
library.add(faTimes); library.add(faSquare); library.add(faCog); library.add(faFileAlt); library.add(faFileAudio); library.add(faFileVideo);
library.add(faFileDownload); library.add(faQuestionCircle); library.add(faBook); library.add(faBullhorn); library.add(faChevronCircleUp); library.add(faInfo); library.add(faLock); library.add(faHome); library.add(faArrowDown);
library.add(faChartLine); library.add(faMicroscope); library.add(faVialVirus); library.add(faGlobe); library.add(faEnvelope); library.add(faMapLocationDot); library.add(faBookmark); library.add(faBullseye); library.add(faScrewdriverWrench); library.add(faQuestion);
library.add(faClipboardList); library.add(faMagnifyingGlassChart); library.add(faMagnifyingGlass); library.add(faFileCode); library.add(faCalendarDays);
library.add(faLocationDot); library.add(faFileSignature); library.add(faPencil); library.add(faHandHoldingDollar); library.add(faGift); library.add(faComments); library.add(faFileCircleQuestion);
library.add(faDesktop); library.add(faShapes); library.add(faVideo);


dom.watch();